/* sidebar Nav */

.navBar {
    position: relative;
  }
  
  .navBar button {
    position: fixed;
    left: 125px;
    top: 10px;
    z-index: 10;
    cursor: pointer;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }
  
  .menuNav {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: -20px;
    background: #0058a3;
    left: -50px;
    bottom: 0;
    height: 100vh;
    width: 0;
    overflow: hidden;
    max-width: 290px;
    z-index: 9;
  }
  
  .menuNav.showMenu {
    width: 100%;
  }
  
  .menuNav.text {
    display: block;
    padding: 10px 40px;
    text-decoration: none;
    /*color: #3fffd2; */
    font-weight: bold;
  }
  
  .menuNav li:first-child {
    /* margin-top: 7rem; */
  }

  .roleSelection {
    vertical-align: bottom;
  }
